var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"rounded-lg",attrs:{"elevation":"0"}},[_c('section',{staticClass:"header-table"},[_c('v-text-field',{staticClass:"rounded-lg",attrs:{"append-icon":"mdi-magnify","label":"Pesquisar...","single-line":"","hide-details":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('div',{staticClass:"space-personalized"}),_c('v-btn',{staticClass:"rounded-lg",attrs:{"elevation":"1","color":"green"},on:{"click":function($event){_vm.dialog= true}}},[_c('span',{staticClass:"textBtn"},[_vm._v(" Novo Perfil ")])])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.profiles,"footer-props":{'items-per-page-text': 'Perfis por Página',},"page":_vm.page,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":"","color":"green"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"icon",attrs:{"size":"22","color":"green","align-item":"center"},on:{"click":function($event){return _vm.editDialog(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-lead-pencil ")])]}}],null,true)},[_c('span',{staticStyle:{"color":"white"}},[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"right":"","color":"#474545"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"icon",attrs:{"size":"22","color":"#474545","align-item":"center"},on:{"click":function($event){return _vm.$router.push({path: '/relatorio-captacao', query:{item:item}})}}},'v-icon',attrs,false),on),[_vm._v("mdi-lock ")])]}}],null,true)},[_c('span',{staticStyle:{"color":"white"}},[_vm._v("Desativar Perfil")])])]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Perfis de Acesso")])]),_c('v-card-text',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveProfile.apply(null, arguments)}}},[_c('v-text-field',{attrs:{"label":"Nome do Perfil"},model:{value:(_vm.profileName),callback:function ($$v) {_vm.profileName=$$v},expression:"profileName"}}),_c('v-btn',{staticStyle:{"margin-right":"10px"},attrs:{"color":"success","type":"submit"},on:{"click":function($event){return _vm.saveProfile()}}},[_vm._v(_vm._s(_vm.buttonName))]),_c('v-btn',{attrs:{"color":"white"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Cancelar")])],1)],1)],1)],1),_c('v-snackbar',{attrs:{"absolute":"","background-color":"white","color":"#104a21"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Fechar ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.message)+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }