<template>
    <div>
      <v-card class="rounded-lg" elevation="0">
        <section class="header-table">
            <v-text-field
            v-model="search"
            class="rounded-lg"
            append-icon="mdi-magnify"
            label="Pesquisar..."
            single-line
            hide-details
            outlined
            dense
            ></v-text-field>
            <div class="space-personalized"></div>
            <v-btn class="rounded-lg" elevation="1" color="green" @click="dialog= true">
                <span class="textBtn"> Novo Perfil </span>
            </v-btn>
        </section>
      
        <v-data-table
            :headers="headers"
            :items="profiles"
            :footer-props="{'items-per-page-text': 'Perfis por Página',}"
            :page="page"
            :loading="loading">

            <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip left color="green">
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                    size="22"
                    class="icon"
                    color="green" 
                    v-on="on"
                    v-bind="attrs"
                    align-item="center"
                    @click="editDialog(item)">mdi-lead-pencil 
                    </v-icon>
                </template>
                    <span style="color:white">Editar</span>
                </v-tooltip>
                <v-tooltip right color="#474545">
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                    size="22"
                    class="icon"
                    color="#474545" 
                    v-on="on"
                    v-bind="attrs"
                    align-item="center"
                    @click="$router.push({path: '/relatorio-captacao', query:{item:item}})">mdi-lock
                    </v-icon>
                </template>
                    <span style="color:white">Desativar Perfil</span>
                </v-tooltip>
            </template>
        </v-data-table>

        <v-dialog v-model="dialog" max-width="600px">
            <v-card>
            <v-card-title>
                <span class="headline">Perfis de Acesso</span>
            </v-card-title>
            <v-card-text>
                <v-form @submit.prevent="saveProfile">
                <v-text-field 
                    v-model="profileName" 
                    label="Nome do Perfil"
                 >
                </v-text-field>
                <v-btn color="success" type="submit" style="margin-right: 10px;" @click="saveProfile()">{{ buttonName }}</v-btn>
                <v-btn color="white" @click="dialog = false">Cancelar</v-btn>
                </v-form>
            </v-card-text>
            </v-card>
        </v-dialog>

        <v-snackbar absolute background-color="white" color="#104a21" v-model="snackbar">
            {{ message }}
            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
                Fechar
                </v-btn>
            </template>
        </v-snackbar>

      </v-card>
    </div>
  </template>
  
  <script>
  import axios from "@/axios";
  
  export default {
    data() {
      return {
        dialog: false,
        headers: [
          {
            text: "Perfil",
            align: "center",
            value: "name",
          },

          { text: "Ações", value: "actions", sortable: false,  align: "center" },
        ],

        displayError: {
          display: false,
          message: [],
        },
        profile_name:"",
        page: 1,
        numberOfPages: 0,
        loading: true,
        options: {},
        sortBy: "name",
        profileName:"",
        items: "",
        profiles: [],
        message: "",
        name: "",
        search:"",
        snackbar: false,
        loadingBTN: false,
        exibirMensagem: false,
        messageError: "",
        buttonName: "SALVAR"
      };
    },
    
    components: {

    },

    created() {
      this.loadTable();
    },

    watch: {
      sortBy: {
        handler() {
  
        }
      },
      options: {
        handler() {
          this.indoDataTable();
        },
      },
      deep: true,
    },
  
    methods: {
      loadTable(){
        axios.get("/profiles").then((response) => {
          this.profiles = response.data.data
          this.loading = false
        });
      },

      editDialog(item){
        this.items = item
        this.buttonName = "EDITAR"
        this.dialog = true
        this.profileName = item.name
      },

      saveProfile(){
        if(this.buttonName === 'SALVAR'){
            let profile = new Object
            profile.name = this.profileName

            axios.post("/profiles", profile).then((response) => {
                this.message = response.data.message;
                this.snackbar = true;
                this.dialog = false;
                this.loadTable()
                this.$router.push({path: '/listar-permissoes', query:{item:response.data.id}})
            
            });
        }else{
            let profile = new Object
            profile.name = this.profileName

            axios.put(`/profiles/${this.items.id}`, profile).then((response) => {
                this.message = response.data.message;
                this.snackbar = true;
                this.dialog = false;
                this.loadTable()
            });
        }
      }
    },
  };
  </script>
  
  <style scoped>
  .header-table {
    display: flex;
    margin-bottom: 20px;
  }

  .d-flex{
    padding: 0 12px;
  }
  
  .space {
    width: 5px;
  }

  .space-personalized{
  padding: 9px;
  }

  .spacediferent {
  width: 7px;
}
  .v-btn:not(.v-btn--round).v-size--default {
    height: 40px !important;
  }
  
  .textBtn {
    font-family: "Poppins";
    color: #ffffff;
  }
  
  .create-user-dialog {
    padding: 20px;
  }
  
  .card {
    padding: 35px;
  }
  
  .banner {
    background: #b3ffab; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #27ae60,
      #1abc9c
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #ee106d,
      #e46987
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
    border-radius: 8px;
  
    margin-bottom: 20px;
  
    display: flex;
    align-items: center;
  }
  
  .icon{

  }

  .banner > h4 {
    color: #ffffff;
    padding: 10px;
  }
  
  .cardFooter {
    padding: 0 !important;
  }
  </style>